import i18next from "i18next";
import React from "react";
import { TypeAnimation } from "react-type-animation";
import { Theme } from "../../Utils/globals";

interface HeroHeaderProps {
  currentTheme: Theme
}
interface HeroHeaderState {

}

class HeroHeader extends React.Component<HeroHeaderProps, HeroHeaderState> {
  render(): React.ReactNode {
    return (
      <section className={`bgimage--${this.props.currentTheme}`} id="home">
        <div className={`container-fluid`}>
          <div className={`row`}>
            <div className={`col-lg-12 col-md-12 col-sm-12 col-xs-12 hero-text--${this.props.currentTheme}`}>
              <h2 className={`hero_title--${this.props.currentTheme}`}>
                <TypeAnimation
                  sequence={[
                    `${i18next.t("HeroHeader:SEQ1")}`,
                    1000,
                    `${i18next.t("HeroHeader:SEQ2")}`,
                    2000,
                    `${i18next.t("HeroHeader:SEQ3")}`,
                    1000,
                    `${i18next.t("HeroHeader:SEQ4")}`,
                    2000,
                    `${i18next.t("HeroHeader:SEQ5")}`,
                    1000,
                    `${i18next.t("HeroHeader:SEQ6")}`,
                    2000,
                    '',
                  ]}
                  repeat={Infinity}
                  speed={{type: 'keyStrokeDelayInMs', value: 200}}
                  deletionSpeed={{type: 'keyStrokeDelayInMs', value: 100}}
                />
              </h2>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default HeroHeader