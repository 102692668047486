import React from 'react';
import i18next from 'i18next';
import { Navbar, Container, Nav, NavDropdown } from 'react-bootstrap';
import { Language, Theme } from '../../Utils/globals';
import onClickOutside from 'react-onclickoutside'

export interface HeaderProps {
  availableLanguages: Array<Language>
  availableThemes: Array<Theme>
  currentTheme: Theme
  navbarExpanded: boolean

  handleLanguageChange: (language: Language) => void
  handleThemeChange: (theme: Theme) => void
  handleNavbarToggle: () => void
  handleNavbarClose: () => void
}

interface HeaderState {
  navbarPosition: "top" | "bottom"
}

class Header extends React.Component<HeaderProps, HeaderState> {
  state: HeaderState = {
    navbarPosition: "top",
  }

  componentDidMount(): void {
    window.addEventListener('scroll', this.handleScroll)
  }

  componentWillUnmount(): void {
    window.removeEventListener('scroll', this.handleScroll)
  }

  handleScroll = (): void => (window.scrollY >= getSectionHeight() - getHeaderHeight())
    ? this.setState({navbarPosition: "bottom"})
    : this.setState({navbarPosition: "top"})

  handleClickOutside = () => {
    if(this.props.navbarExpanded){
      this.props.handleNavbarToggle()
    }
  }

  render(): React.ReactNode {
    return (
      <Navbar className={`navbarBackground ${this.props.currentTheme} ${this.state.navbarPosition} `} collapseOnSelect expand="lg" fixed="top" expanded={this.props.navbarExpanded}>
        <Container>
          <Navbar.Brand key={"nav-brand"} className={`brand--${this.props.currentTheme}`} href="#"></Navbar.Brand>
          <Navbar.Toggle key={"nav-toggle"} className={`toggler--${this.props.currentTheme}`} aria-controls="responsive-navbar-nav" onClick={this.props.handleNavbarToggle} />
          <Navbar.Collapse key={"nav-collapse"} id="responsive-navbar-nav">
            <Nav key={"nav-hooks"} className="me-auto" activeKey={"#"}>
              <Nav.Link key={"navlink-home"} className={`navlink--${this.props.currentTheme}`} href="#home" onClick={this.props.handleNavbarClose}>{i18next.t("Header:Home")}</Nav.Link>
              <Nav.Link key={"navlink-about"} className={`navlink--${this.props.currentTheme}`} href="#about" onClick={this.props.handleNavbarClose}>{i18next.t("Header:About")}</Nav.Link>
              <Nav.Link key={"navlink-stack"} className={`navlink--${this.props.currentTheme}`} href="#stack" onClick={this.props.handleNavbarClose}>{i18next.t("Header:Stack")}</Nav.Link>
              <Nav.Link key={"navlink-projects"} className={`navlink--${this.props.currentTheme}`} href="#projects" onClick={this.props.handleNavbarClose}>{i18next.t("Header:Projects")}</Nav.Link>
            </Nav>
            <Nav key={"nav-custom"} className="float-lg-end" activeKey={"#"}>
              {LanguageButtons(
                this.props.availableLanguages,
                this.props.currentTheme,
                this.props.handleLanguageChange
              )}
              {ThemeButtons(
                this.props.availableThemes,
                this.props.currentTheme,
                this.props.handleThemeChange
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>
    )
  }
}

const capitalizeLanguage = (language: Language): string => language.toUpperCase()

const getThemeName = (theme: Theme): string => theme === "black" ? i18next.t("Header:Black") : theme === "green" ? i18next.t("Header:Green") : i18next.t("Header:Yellow")

function LanguageButtons(
  availableLanguages: Array<Language>,
  currentTheme: Theme,
  handleLanguageChange: (language: Language) => void
) {
  return (
    <NavDropdown
      key={"dropdown-language"}
      id="nav-dropdown-dark-example"
      title={i18next.t("Header:Languages")}
      menuVariant={currentTheme === "black" ? "dark" : "light"}
    >
      {availableLanguages.map(language => (
        <NavDropdown.Item key={`item-${language}`} onClick={() => handleLanguageChange(language)}>{capitalizeLanguage(language)}</NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

function ThemeButtons(
  availableThemes: Array<Theme>,
  currentTheme: Theme,
  handleThemeChange: (theme: Theme) => void
) { 
  return (
    <NavDropdown
      key={"dropdown-theme"}
      id="nav-dropdown-dark-example"
      title={i18next.t("Header:Themes")}
      menuVariant={currentTheme === "black" ? "dark" : "light"}
    >
      {availableThemes.map(theme => (
        <NavDropdown.Item key={`item-${theme}`} onClick={() => handleThemeChange(theme)}>{getThemeName(theme)}</NavDropdown.Item>
      ))}
    </NavDropdown>
  );
}

const getSectionHeight = (): number => {
  let homeElement = document.getElementById("home")
  if (homeElement != null) {
    return homeElement.clientHeight
  }
  return 550
}

const getHeaderHeight = (): number => {
  let header = document.querySelector('.navbar');
  if (header != null) {
    return header.clientHeight
  }
  return 100
}


export default onClickOutside(Header);
