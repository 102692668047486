import React from 'react';
import './App.css';

import { initializeTranslations } from './Utils/i18init';
import HeroHeader from './Components/Sections/HeroHeader'
import About from './Components/Sections/About';
import Stack from './Components/Sections/Stack';
import Projects from './Components/Sections/Projects';
import Footer from './Components/Footer/Footer';
import { DEFAULT_LANGUAGE, DEFAULT_THEME, Language, Theme } from './Utils/globals';
import Header from './Components/Header/Header';

export interface Props {
  availableLanguages: Array<Language>
  availableImages: Array<Theme>
}

interface State {
  currentLanguage: Language
  currentTheme: Theme
  navbarExpanded: boolean
}

class App extends React.Component<Props, State> {
  
  state: State = { 
    currentLanguage: detectLanguage(),
    currentTheme: DEFAULT_THEME,
    navbarExpanded: false
  }

  onLanguageChange = (language: Language) => {
    this.updateLanguage(language)
    initializeTranslations(this.state.currentLanguage)
  }

  onThemeChange = (theme: Theme) => {
    this.updateTheme(theme)
  }

  onToggleNavbar = () => {
    this.toggleNavbar()
  }

  onCloseNavbar = () => {
    this.closeNavbar()
  }

  render(): React.ReactNode {
    initializeTranslations(this.state.currentLanguage)
    return (
      <div className={`App ${this.state.currentTheme}`}>
        <Header 
          availableLanguages={this.props.availableLanguages} handleLanguageChange={this.onLanguageChange}
          availableThemes={this.props.availableImages}
          currentTheme={this.state.currentTheme}
          handleThemeChange={this.onThemeChange} 
          navbarExpanded={this.state.navbarExpanded}    
          handleNavbarToggle={this.onToggleNavbar} 
          handleNavbarClose={this.onCloseNavbar}
        />
        <HeroHeader 
          currentTheme={this.state.currentTheme}
        />
        <About 
          currentTheme={this.state.currentTheme}
        />
        <Stack 
          currentTheme={this.state.currentTheme}
        />
        <Projects 
          currentTheme={this.state.currentTheme}
        />
        <Footer 
          currentTheme={this.state.currentTheme}
        />
      </div>
    )
  }

  updateLanguage(change: Language){
    this.setState((currentState) => {
      return { currentLanguage: change, navbarExpanded: false }
    })
  }
  updateTheme(change: Theme){
    this.setState((currentState) => {
      return { currentTheme: change, navbarExpanded: false }
    })
  }
  toggleNavbar(){
    this.setState((currentState) => {
      return { navbarExpanded: !currentState.navbarExpanded }
     })
  }
  closeNavbar(){
    this.setState((currentState) => {
      return { navbarExpanded: false }
     })
  }
}

const detectLanguage = (): Language => {
  let lang = navigator.language
  if (lang == "en" || lang == "en-EN" || lang == "en-US") return "en"
  if (lang == "nl" || lang == "nl-NL") return "nl"
  if (lang == "pl" || lang == "pl-PL") return "pl"

  return DEFAULT_LANGUAGE
}

export default App;
