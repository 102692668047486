import i18next from "i18next";
import React from "react";
import { SiReact, SiDotnet, SiDrupal, SiDocker, SiLinux } from 'react-icons/si'
import { FaDatabase } from 'react-icons/fa'
import { Theme } from "../../Utils/globals";

interface StackProps {
  currentTheme: Theme
}
interface StackState {

}

class Stack extends React.Component<StackProps, StackState> {
  render(): React.ReactNode {
    return (
      <section id="stack">
        <div className="container">
          <h1 className="text-center">{i18next.t("Stack:Stack")}</h1>
          <div className="row">
            <div className="col-lg-4 mt-4">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiReact />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Stack:Card1:Title")}</h4>
                  <p className="card-text mt-3">{i18next.t("Stack:Card1:Description")}</p>
                </div>
              </div>
            </div>
            <div className="col-lg-4 mt-4">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiDotnet />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Stack:Card2:Title")}</h4>
                  <p className="card-text mt-3">{i18next.t("Stack:Card2:Description")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiDrupal />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Stack:Card3:Title")}</h4>
                  <p className="card-text mt-3">{i18next.t("Stack:Card3:Description")}</p>
                </div>
              </div>
            </div>
          </div>

          <div className="row">
            <div className="col-lg-4 mt-4">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiDocker />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Stack:Card4:Title")}</h4>
                  <p className="card-text mt-3">{i18next.t("Stack:Card4:Description")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <SiLinux />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Stack:Card5:Title")}</h4>
                  <p className="card-text mt-3">{i18next.t("Stack:Card5:Description")}</p>
                </div>
              </div>
            </div>

            <div className="col-lg-4 mt-4">
              <div className={`card servicesText ${this.props.currentTheme}`}>
                <div className={`card-body ${this.props.currentTheme}`}>
                  <div className={`servicesIcon ${this.props.currentTheme}`}>
                    <FaDatabase />
                  </div>
                  <h4 className="card-title mt-3">{i18next.t("Stack:Card6:Title")}</h4>
                  <p className="card-text mt-3">{i18next.t("Stack:Card6:Description")}</p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}

export default Stack