import i18next from "i18next";
import React from "react";
import campus from "../../Utils/Images/websites/campus.png"
import diak from "../../Utils/Images/websites/diak.png"
import hs from "../../Utils/Images/websites/hs.png"
import reuma from "../../Utils/Images/websites/reuma.png"
import { Carousel } from "react-bootstrap";
import { Theme } from "../../Utils/globals";

interface ProjectsProps {
  currentTheme: Theme
}
interface ProjectsState {

}

class Projects extends React.Component<ProjectsProps, ProjectsState> {
  render(): React.ReactNode {
    return (
      <section id="projects">
        <div className="container mt-4 pt-4">
          <h1 className="text-center">{i18next.t("Projects:Projects")}</h1>
          {CarouselProject(this.props.currentTheme)}
        </div>
      </section>
    )
  }
}

function CarouselProject(currentTheme: Theme) {
  return (
    <Carousel variant="dark" indicators={false} interval={10000000000}>
      <Carousel.Item>
        <div className="clearfix">
          <img src={campus} className="img-fluid col-md-6 float-md-end mb-3 ms-md-3" alt="alt"></img>
          <h3>{i18next.t("Projects:Project1:Title")}</h3>
          <p>{i18next.t("Projects:Project1:Description")}</p>
          <a target="_blank" rel="noopener noreferrer" href="https://www.sshxl.nl/nl/campuscontrole" className={`btn btn-success ${currentTheme}`}>{i18next.t("Projects:Project1:Link")}</a>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="clearfix">
          <img src={hs} className="img-fluid col-md-6 float-md-end mb-3 ms-md-3" alt="alt"></img>
          <h3>{i18next.t("Projects:Project2:Title")}</h3>
          <p>{i18next.t("Projects:Project2:Description")}</p>
          <a target="_blank" rel="noopener noreferrer" href="https://www.hartstichting.nl/" className={`btn btn-success ${currentTheme}`}>{i18next.t("Projects:Project2:Link")}</a>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="clearfix">
          <img src={diak} className="img-fluid col-md-6 float-md-end mb-3 ms-md-3" alt="alt"></img>
          <h3>{i18next.t("Projects:Project3:Title")}</h3>
          <p>{i18next.t("Projects:Project3:Description")}</p>
          <a target="_blank" rel="noopener noreferrer" href="https://www.diakclinic.nl/" className={`btn btn-success ${currentTheme}`}>{i18next.t("Projects:Project3:Link")}</a>
        </div>
      </Carousel.Item>
      <Carousel.Item>
        <div className="clearfix">
          <img src={reuma} className="img-fluid col-md-6 float-md-end mb-3 ms-md-3" alt="alt"></img>
          <h3>{i18next.t("Projects:Project4:Title")}</h3>
          <p>{i18next.t("Projects:Project4:Description")}</p>
          <a target="_blank" rel="noopener noreferrer" href="https://www.reuma.nl/" className={`btn btn-success ${currentTheme}`}>{i18next.t("Projects:Project4:Link")}</a>
        </div>
      </Carousel.Item>
    </Carousel>
  );
}

export default Projects