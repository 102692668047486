import i18next from "i18next";
import React from "react";
import miura from "../../Utils/Images/miura.jpg"
import mercedes from "../../Utils/Images/mercedes.png"
import camaro from "../../Utils/Images/camaro.png"
import { Theme } from "../../Utils/globals";

interface AboutProps {
  currentTheme: Theme
}
interface AboutState {

}

class About extends React.Component<AboutProps, AboutState> {
  render(): React.ReactNode {
    return (
      <section id="about">
        <div className="container mt-4 pt-4">
          <h1 className="text-center">{i18next.t("AboutMe:Title")}</h1>
          <div className="row mt-4">
            <div className="col-lg-4">
              <img src={
                this.props.currentTheme == "black" ? camaro
                : this.props.currentTheme == "yellow" ? mercedes
                : miura
                } className="imageAboutPage" alt="alt"></img>
            </div>
            <div className="col-lg-8">
              <p>{i18next.t("AboutMe:DescriptionTop")}</p>
              <div className="row mt-3">
                <div className="col-md-6">
                  <ul>
                    <li>{i18next.t("AboutMe:Name")}</li>
                    <li>{i18next.t("AboutMe:Age")}</li>
                    <li>{i18next.t("AboutMe:WhereFrom")}</li>
                  </ul>
                </div>
                <div className="col-md-6">
                  <ul>
                    <li>{i18next.t("AboutMe:Occupation")}</li>
                    <li>{i18next.t("AboutMe:Experience")}</li>
                    <li>{i18next.t("AboutMe:CurrentEmployer")}</li>
                  </ul>
                </div>
              </div>
              <div className="row mt-3">
                <p>{i18next.t("AboutMe:DescriptionBottom")}</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    )
  }
}


export default About