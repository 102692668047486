import i18next from "i18next";
import React from "react";
import { FaFacebookSquare, FaInstagramSquare, FaLinkedin, FaGithubSquare } from "react-icons/fa"
import { Theme } from "../../Utils/globals";

interface FooterProps {
  currentTheme: Theme
}
interface FooterState {

}

class Footer extends React.Component<FooterProps, FooterState> {
  render(): React.ReactNode {
    return (
      <footer className={`bg-dark bg-gradient bg-opacity-75 text-center text-white ${this.props.currentTheme}`}>
        <div className="container p-4 pb-0">
          <section className="mb-4">
            <a className="btn btn-outline-light btn-floating m-1" href="https://www.facebook.com/andrzej.lech.988/" target="_blank" rel="noreferrer noopener" role="button"
            ><FaFacebookSquare /></a>
            <a className="btn btn-outline-light btn-floating m-1" href="https://www.instagram.com/andrzej.lech.988/" target="_blank" rel="noreferrer noopener" role="button"
            ><FaInstagramSquare /></a>
            <a className="btn btn-outline-light btn-floating m-1" href="https://www.linkedin.com/in/andrzej-lech-848838197/" rel="noreferrer noopener" target="_blank" role="button"
            ><FaLinkedin /></a>
            <a className="btn btn-outline-light btn-floating m-1" href="https://github.com/adziusmaster" target="_blank" rel="noreferrer noopener" role="button"
            ><FaGithubSquare /></a>
          </section>
        </div>

        <div className={`text-center p-3 footer-dark-background ${this.props.currentTheme}`}>
          {i18next.t("Footer:Legal")}
        </div>
      </footer>
    )
  }
}

export default Footer